

type ParagraphComponentData = {
    content: string
}

function ParagraphComponent({ data }: { data: ParagraphComponentData }) {
    return <>
        <div dangerouslySetInnerHTML={{__html: data.content}}></div>
    </>
}

export default ParagraphComponent