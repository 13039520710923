import { CmsComponent, CmsPage, CmsSlot } from '@stnimmerhome/webinterface-data-model'
import { API_URL } from '../constants'

export const cmsAdapter = {
    getPageByPath: (path: string): Promise<CmsPage> => {
        const params = new URLSearchParams({
            path: path
        })
        return fetch(`${API_URL}/pages/byPath?${params}`).then(res => res.json()) as Promise<CmsPage>
    },
    getSlot: (slotUid: string): Promise<CmsSlot> => {
        return fetch(`${API_URL}/slots/${slotUid}`).then(result => result.json()) as Promise<CmsSlot>
    },
    getComponent: (componentUid: string): Promise<CmsComponent> => {
        return fetch(`${API_URL}/components/${componentUid}`).then(res => res.json()) as Promise<CmsComponent>
    }
}