import styles from './Component.module.css'
import { CmsComponent } from '@stnimmerhome/webinterface-data-model'
import ParagraphComponent from '../cms-components/paragraph/ParagraphComponent'
import UnknownComponent from '../cms-components/unknown/UnknownComponent'
import { COMPONENT_TYPES } from '../constants'

function Component({ data: component }: { data: CmsComponent }) {
    return <div className={styles.Component}>
        {component ? getSpecificComponent(component) : ''}
    </div>

    function getSpecificComponent(component: CmsComponent) {
        const componentType = component.type as (typeof COMPONENT_TYPES)[number]
        switch (componentType) {
            case 'Paragraph':
                return <ParagraphComponent data={component as any}/>
            case 'Image':
                return ''
            default:
                const a: never = componentType
                return <UnknownComponent data={component as any}/>
        }
    }

}

export default Component