import { CmsSlot } from '@stnimmerhome/webinterface-data-model'
import styles from './Slot.module.css'
import Component from '../component/component'

function Slot({ data: slot }: { data: CmsSlot }) {
    return <div className={`${styles.Slot} ${toClassName(slot?.style)}`}>
        {slot ? createComponents(slot) : ''}
    </div>
}

function createComponents(slot: CmsSlot) {
    return slot.components.map(component => <Component data={component} key={component.uid}/>)
}

function toClassName(slotStyle: string | undefined) {
    switch (slotStyle) {
        case 'FULL-WIDTH':
            return styles.FullWidth
        case 'HALF-WIDTH':
            return styles.HalfWidth
        case 'THIRD-WIDTH':
            return styles.ThirdWidth
        case 'QUARTER-WIDTH':
            return styles.QuarterWidth
        default:
            return ''
    }
}

export default Slot