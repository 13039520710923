import { useEffect, useState } from 'react'
import { cmsAdapter } from '../adapter/cms-adapter'
import { CmsPage, CmsSlot } from '@stnimmerhome/webinterface-data-model'
import Slot from '../slot/Slot'
import { BASE_TITLE } from '../constants'
import styles from './Page.module.css'

function Page({ path }: { path: string }) {
    const [page, setPage] = useState(null as CmsPage | null)

    useEffect(() => {
        cmsAdapter.getPageByPath(path).then(
            pageFromApi => {
                setPage(pageFromApi)
                document.title = `${pageFromApi.title} | ${BASE_TITLE}`
            }
        )
    }, [])

    return <div className={styles.Slots}>
        {page ? createSlots(page.slots) : ''}
    </div>

    function createSlots(slots: CmsSlot[]) {
        return slots.map(slot => <Slot data={slot} key={slot.uid}/>)
    }
}

export default Page